import React from 'react'
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('password');
        navigate('/login');
    };

    return (
        <>
            <ul
                className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                id="accordionSidebar"
            >
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                    <img
                        src="admin_assets/img/logo.png"
                        className="sidebar-brand-icon"
                        alt="Logo"
                        style={{ width: 'auto', height: '287%', maxWidth: '100%', marginTop: '20px' }}
                    />
                    <div className="sidebar-brand-text mx-3">
                    </div>
                </a>
                <br />
                <hr className="sidebar-divider my-0" />
                <br />
                <li className="nav-item">
                    <a
                        className="dropdown-item text-white"
                        href={() => false}
                        style={{cursor: 'pointer'}}
                    >
                        <i className="fas fa-tachometer-alt fa-sm fa-fw mr-2 text-white-400" />
                        DashBoard
                    </a>
                </li>
                <br />
                <hr className="sidebar-divider" />
                <li className="nav-item">
                    <a
                        className="dropdown-item text-white"
                        href={() => false}
                        data-toggle="modal"
                        data-target="#logoutModal"
                        style={{cursor: 'pointer'}}
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-white-400" />
                        Logout
                    </a>
                </li>
            </ul>
        </>
    )
}

export default Sidebar
