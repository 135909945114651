import React, { useState } from 'react';
import './css/Videos.css';

const Videos = () => {
    const [activeTab, setActiveTab] = useState('cristo1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="vh-section-outer mt-5">
                <div className="section-inner">
                    <div className="container-fluid">
                        <div className="section-heading mt-2">
                            <br />
                            <h2 className="entry-title text-black text-center font-weight-bold mb-5 mt-5">Our Product Videos</h2>
                        </div>
                        <div className="row">
                            <div className="tabSection">
                                <ul className="nav nav-pills" role="tablist">
                                    <li role="presentation" className={`category-link ${activeTab === 'cristo1' ? 'active' : ''}`}>
                                        <a
                                            href="#cristo1"
                                            onClick={() => handleTabClick('cristo1')}
                                            data-bs-target="#cristo1"
                                            aria-controls="cristo1"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <p className="hidden-xs font-weight-bold text-white">Parokia</p>
                                        </a>
                                    </li>
                                    <li role="presentation" className={`category-link ${activeTab === 'religio1' ? 'active' : ''}`}>
                                        <a
                                            href="#religio1"
                                            onClick={() => handleTabClick('religio1')}
                                            data-bs-target="#religio1"
                                            aria-controls="religio1"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <p className="hidden-xs font-weight-bold text-white">ReligiO</p>
                                        </a>
                                    </li>
                                    <li role="presentation" className={`category-link ${activeTab === 'cathedra1' ? 'active' : ''}`}>
                                        <a
                                            href="#cathedra1"
                                            onClick={() => handleTabClick('cathedra1')}
                                            data-bs-target="#cathedra1"
                                            aria-controls="cathedra1"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <p className="hidden-xs font-weight-bold text-white">Cathedra</p>
                                        </a>
                                    </li>
                                    <li role="presentation" className={`category-link ${activeTab === 'charisma1' ? 'active' : ''}`}>
                                        <a
                                            href="#charisma1"
                                            onClick={() => handleTabClick('charisma1')}
                                            data-bs-target="#charisma1"
                                            aria-controls="charisma1"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <p className="hidden-xs font-weight-bold text-white">Charisma</p>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    {activeTab === 'cristo1' && (
                                        <div id="cristo1" className="tab-pane active">
                                            <iframe
                                                width="500"
                                                height="300"
                                                style={{
                                                    border: 'none',
                                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                                                    borderRadius: '10px',
                                                    marginBottom: '20px',
                                                    marginTop: '20px',
                                                    padding: '10px'
                                                }}
                                                src="https://www.youtube.com/embed/JG3n6HjA06M?si=QCWlrmpZiTjnxgmS"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture allowfullscreen"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    )}
                                    {activeTab === 'religio1' && (
                                        <div id="religio1" className="tab-pane active">
                                            <p className='text-center font-weight-bold'>Coming Soon</p>
                                        </div>
                                    )}
                                    {activeTab === 'cathedra1' && (
                                        <div id="cathedra1" className="tab-pane active">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <iframe
                                                        width="500"
                                                        height="300"
                                                        style={{
                                                            border: 'none',
                                                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                                                            borderRadius: '10px',
                                                            marginBottom: '20px',
                                                            marginTop: '20px',
                                                            padding: '10px'
                                                        }}
                                                        src="https://www.youtube.com/embed/SwdM-5KWjgw"
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture allowfullscreen"
                                                        referrerPolicy="strict-origin-when-cross-origin"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 'charisma1' && (
                                        <div id="charisma1" className="tab-pane active">
                                            <p className='text-center font-weight-bold'>Coming Soon</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Videos;
