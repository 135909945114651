import React from 'react'

const Footbar = () => {
    return (
        <>
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span> <p>
                            {" "}
                            © {new Date().getFullYear()} CristO
                            <br /> Powered by
                            <a
                                href="https://www.boscosofttech.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                Boscosoft Technologies
                            </a>
                        </p></span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footbar
