import React, { useState } from 'react';
import Swal from 'sweetalert2';

const EmailModal = ({ targetedUser }) => {

    const [message, setMessage] = useState('');
    const handleSendEmail = async () => {
        if (message.trim() === '') {
            Swal.fire("Error", "Message cannot be empty!", "error");
            return;
        }

        try {
            const response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': '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'
                },
                body: JSON.stringify({
                    sender: { name: 'CristO', email: 'imman@boscosofttech.com' },
                    to: [{ email: targetedUser?.email, name: targetedUser?.name }],
                    subject: 'CristO - Reply',
                    htmlContent: `<html><body><p>${message}</p></body></html>`
                })
            });

            if (response.ok) {
                Swal.fire("Success", "Email sent successfully!", "success");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else if (response.status === 400) {
                Swal.fire("Error", "Failed to send email. Please try again.", "error");
            } else if (response.status === 401) {
                Swal.fire("Error", "Unauthorized. Please try again.", "error");
            } else if (response.status === 500) {
                Swal.fire("Error", "Internal server error. Please try again.", "error");
            } else if (response.status === 503) {
                Swal.fire("Error", "Service unavailable. Please try again.", "error");
            } else if (response.status === 504) {
                Swal.fire("Error", "Gateway timeout. Please try again.", "error");
            } else if (response.status === 429) {
                Swal.fire("Error", "Too many requests. Please try again.", "error");
            } else {
                Swal.fire("Error", "Failed to send email. Please try again.", "error");
            }
        } catch (error) {
            Swal.fire("Error", "An error occurred. Please try again.", "error");
        }
    };

    return (
        <div
            className="modal fade"
            id="exampleMailCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleMailCenterTitle"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleEmailLongTitle">
                            Reply to {targetedUser?.name}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="recipient">To:</label>
                            <input type="text" className="form-control" id="recipient" value={targetedUser?.email} readOnly />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea
                                className="form-control"
                                id="message"
                                rows="3"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            id='cancel'
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSendEmail}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailModal;
