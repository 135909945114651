import React, { useCallback, useEffect, useState } from 'react';
import EmailModal from './EmailModal';
import axios from 'axios';
import { ApiUrl } from '../../API/ApiUrl';

const Table = () => {
    const [formData, setFormData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [targetedUser, setTargetedUser] = useState({});

    useEffect(() => {
        axios.get(`${ApiUrl}/cristo/view`)
            .then(response => {
                const data = response.data.data;
                setFormData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    const handleCheckboxChange = useCallback((event, index) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, index]);
        } else {
            setSelectedRows(selectedRows.filter(row => row !== index));
        }
    }, [selectedRows]);

    const handleSelectAllCheckboxChange = useCallback((event) => {
        if (event.target.checked) {
            const allIndexes = Array.from({ length: formData.length }, (_, i) => i);
            setSelectedRows(allIndexes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    }, [formData]);

    const generateCSV = useCallback(() => {
        if (formData.length === 0 || selectedRows.length === 0) {
            console.log("No data selected for CSV generation.");
            return;
        }

        const csvData = [
            ['S.No', 'Name', 'Mobile', 'Email', 'Products', 'Message'],
            ...selectedRows.map(index => [
                index + 1,
                formData[index].name,
                formData[index].mobile,
                formData[index].email,
                formData[index].products,
                formData[index].message,
            ]),
        ];

        const csvContent = csvData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'report.csv');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, [formData, selectedRows]);


    const handleManualDownload = () => {
        generateCSV();
    };

    return (
        <>
            <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800 mt-3">Request for Demo List</h1>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-3">
                    <button
                        className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm text-white"
                        onClick={handleManualDownload}
                    >
                        <i className="fas fa-download fa-sm text-white-500" /> Generate Report
                    </button>
                </div>
                <div className="card shadow mb-4 mt-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Request List</h6>
                    </div>
                    <div className="card-body">
                        {loading ? ( // Render loading indicator if data is still loading
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" checked={selectAll} onChange={handleSelectAllCheckboxChange} /></th>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Request Product</th>
                                            <th>Request Date</th>
                                            <th>Message</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.length > 0 ? formData?.map((data, index) => (
                                            <tr key={index}>
                                                <td> <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(index)}
                                                    onChange={(event) => handleCheckboxChange(event, index)}
                                                /></td>
                                                <td>{index + 1}</td>
                                                <td>{data.name}</td>
                                                <td>{data.mobile}</td>
                                                <td>{data.email}</td>
                                                <td>{data.products}</td>
                                                <td>{data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : '-'}</td>
                                                <td>{data.message}</td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} title="Send Email" onClick={() => {
                                                        setTargetedUser(data);
                                                    }} data-toggle="modal" data-target="#exampleMailCenter">
                                                        <i className="fa fa-envelope"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr><td colSpan="9" className="text-center">No data available</td></tr>
                                        )}
                                    </tbody>
                                </table>
                                <EmailModal targetedUser={targetedUser} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table;
