import React from 'react'
import Sidebar from './Layouts/Sidebar'
import Navbar from './Layouts/Navbar'
import Footbar from './Layouts/Footbar'
import Logout from './Layouts/Logout'
import Table from './Layouts/Table'

const MainDashLayout = () => {
    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <div className="row">
                                <Table />
                            </div>
                        </div>
                    </div>
                    <Footbar />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up" />
            </a>
            <Logout />
        </>
    )
}

export default MainDashLayout