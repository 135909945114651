import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

const FormContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin-top: 10rem;
    margin-left: 20rem;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 5px;
    background-color: #fff;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.2em;
`;

const Input = styled.input`
    padding: 10px;
    font-size: 1.2em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    :disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const LoadingIcon = styled.span`
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top: 2px solid #4CAF50;
    animation: spin 1s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
            if (!emailRegex.test(email)) {
                Swal.fire('Error', 'Invalid Email', 'error').then(() => setLoading(false));
            } else if (password.trim() === '') {
                Swal.fire('Error', 'Password is required', 'error').then(() => setLoading(false));
            } else if (email === 'admin@gmail.com' && password === 'admin@gmail.com') {
                Swal.fire('Success', 'Login Successful', 'success').then(() => {
                    sessionStorage.setItem('email', email);
                    sessionStorage.setItem('password', password);
                    navigate('/dashboard');
                });
            } else {
                Swal.fire('Error', 'Invalid Email or Password', 'error').then(() => setLoading(false));
            }
        }, 1000);
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container mt-5">
            <FormContainer>
                <Form onSubmit={handleSubmit}>
                    <Label>Email <span style={{ color: 'red' }}>*</span></Label>
                    <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                    />
                    <Label>Password <span style={{ color: 'red' }}>*</span></Label>
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        style={{ paddingRight: '2.5rem' }}
                    />
                    {showPassword ? (
                        <FontAwesomeIcon
                            icon={faEye}
                            style={{ position: 'absolute', top: '29%', right: '43%', transform: 'translateY(-50%)' }}
                            onClick={handleTogglePassword}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faEyeSlash}
                            style={{ position: 'absolute', top: '29%', right: '43%', transform: 'translateY(-50%)' }}
                            onClick={handleTogglePassword}
                        />
                    )}
                    <Button type="submit" disabled={loading}>
                        {loading ? <LoadingIcon /> : 'Login'}
                    </Button>
                </Form>
            </FormContainer>
        </div>
    );
};

export default Login;

